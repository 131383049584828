	var klaroConfig = {
		privacyPolicy: 'http://www.escolaswing.com/politica-cookies',
		elementID: 'klaro', // div id
		storageMethod: 'cookie', // storage of user preferences 'cookie' (default) or 'localStorage'
		cookieName: 'klaro', // cookie name of klaro
		cookieExpiresAfterDays: 365, // cookie expiration, 120 (default)
		default: false, //default state of application true (default)
		mustConsent: false, //if true show popup at fist moment
		acceptAll: false, // show accept all button
		hideDeclineAll: false, // show decline all button
		// This is a list of third-party apps that Klaro will manage for you.
		apps: [
			{
				name: 'youtube',
				title: 'Cookies de Youtube',
				required: true,
				purposes: ['necessary'],
			},
			{
				name: 'googleAnalytics',
				title: 'Google Analytics',
				default: true,
				purposes: ['statistics'], //analytics, security, livechat, advertising, styling
				cookies: [/^_g.*/i], // needed for delete de cookies
				required: false, //cookie obligatoria
				optOut: false, //load before consent
				onlyOnce: true, //script loaded once
				callback: klarocall
			},
			{
        name: 'Klaro',
        title: 'Klaro',
        required: true,
				purposes: ['necessary'],
      },
		],
		translations: {
			ca: {
				consentModal: {
					title: 'Cookies que es fan servir en aquesta web',
					description: 'Aquí pots veure i personalitzar les cookies que fem servir a la web.',
        },
        privacyPolicy: {
          text: 'Per a més informació, fes click a la nostra {privacyPolicy}.',
          name: 'política de cookies',
        },
				consentNotice: {
					changeDescription: 'Hi ha hagut canvis des de la teva última visita, si us plau, actualitza el teu consentiment.',
					description: "Aquesta pàgina web utilitza cookies. Les cookies d'aquest lloc web només s'utilitzen per personalitzar el contingut, oferir funcions de xarxes socials i analitzar el trànsit. No emmagatzemem informació personal.",
					learnMore: 'Configurar',
				},
				ok: 'Acceptar',
				acceptAll: 'Acceptar totes',
				acceptSelected: 'Acceptar les selecccionades',
				purposes: {
					necessary: 'Necessària',
					analytics: 'Analítica',
					statistics: 'Estadística',
					security: 'Seguretat',
					livechat: 'Livechat',
					advertising: 'Publicitat',
					styling: 'Estil',
				},
				googleAnalytics: {
					title: 'Cookies de Google Analytics',
					description: "Cookies d'estadístiques ajuden als propietaris del web a comprendre com els visitants interactuen amb el lloc mitjançant la recopilació d'informació anònima.",
				},
				youtube: {
          title: 'Cookies de Youtube',
					description: "Cookies per emmagatzemar les preferències del reproductor de vídeo de l'usuari que utilitza el vídeo incrustat de YouTube",
        },
        klaro: {
          title: 'Klaro',
					description: "Gestor de consentiments de cookies",
				},
			}
		},
	};